// ---------------
// REACT
// ---------------
import React from 'react';
import PropTypes from 'prop-types';
// ---------------
// DATA
// ---------------
import data from '../../content/data';

// ---------------
// COMPONENTS
// ---------------
import Header from './header/header';
import Footer from './footer/footer';

// ---------------
// STYLES
// ---------------
import '../styles/base/manifest.scss';


const Layout = ( { children, is404 } ) => (
	<>
		{!is404 && (
			<Header siteTitle={data.name}/>
		)}

		<main>{children}</main>

		{!is404 && (
			<Footer />
		)}
	</>
);

Layout.defaultProps = {
	children: null,
	is404: false,
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	is404: PropTypes.bool,
};

export default Layout;
