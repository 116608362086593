const data = {
	'name': 'Nathan Foon',
	'description': 'Nathan Foon is a web developer based in Toronto.',
	'longDescription': 'I\'m a web developer based in Toronto.',
	'occupation': 'Web Developer',
	'occupationalCategory': '15-1134.00 Web Developers',
	'url': 'https://foon.ca',
	'contact': {
		'email': {
			'public': 'hey@foon.ca',
			'personal': 'nathan@foon.ca',
		},
		'website': 'foon.ca',
		'phone': '416 587 4532',
	},
	'address': {
		'street': '604-60 Gloucester St',
		'city': 'Toronto',
		'province': 'ON',
		'postal': 'M4Y 1L7',
	},
	'social': [
		{
			'name': 'Twitter',
			'show': false,
			'url': 'https://twitter.com/nathanfoon',
		},
		{
			'name': 'LinkedIn',
			'show': true,
			'url': 'https://www.linkedin.com/in/nathanfoon/',
		},
		{
			'name': 'CodePen',
			'show': false,
			'url': 'https://codepen.io/nathanfoon/',
		},
		{
			'name': 'GitHub',
			'show': false,
			'url': 'https://github.com/nathanfoon',
		},
		{
			'name': 'Instagram',
			'show': false,
			'url': 'https://www.instagram.com/nathanfoon/',
		},
	],
	'projectsWorkedOn': {
		'heading': 'Work',
		'description': 'A selection of projects that I\'ve made contributions to',
		'items': [
			{
				'heading': 'Agency',
				'projects': [
					{
						'show': true,
						'name': 'Weddingbells',
						'url': 'https://weddingbells.ca/',
					},
					{
						'show': true,
						'name': 'Investment Readiness Program',
						'url': 'https://irp-ppi.ca/',
					},
					{
						'show': false,
						'name': 'Calgary TELUS Convention Centre',
						'url': 'https://calgary-convention.com/',
					},
					{
						'show': true,
						'name': 'Broadview Magazine',
						'url': 'https://broadview.org/',
					},
					{
						'show': true,
						'name': 'Alliance 2030',
						'url': 'https://alliance2030.ca/',
					},
					{
						'show': false,
						'name': 'Passport 2017',
						'url': 'https://passport2017.ca/',
					},
					{
						'show': false,
						'name': 'Toronto Cocktail Week',
						'url': 'https://torontococktailweek.ca/',
					},
					{
						'show': true,
						'name': 'Canon Large Format Printing',
						'url': 'https://lfp.canon.ca/',
					},
					{
						'show': false,
						'name': 'FASHION Magazine',
						'url': 'https://fashionmagazine.com/',
					},
					{
						'show': false,
						'name': 'Toronto Life',
						'url': 'https://torontolife.com/',
					},
					{
						'show': false,
						'name': 'MagNet',
						'url': 'https://magnet.magazinescanada.ca/',
					},
					{
						'show': false,
						'name': 'Canadian Magazine Awards',
						'url': 'https://maggrandsprix.ca/',
					},
					{
						'show': false,
						'name': 'Maker Pizza',
						'url': 'http://www.makerpizza.com/',
					},
					{
						'show': true,
						'name': 'Distinctive Advisors',
						'url': 'https://www.distinctiveadvisors.com/',
					},
					{
						'show': true,
						'name': 'Brazilian Canadian Coffee Company',
						'url': 'https://braziliancoffeeco.com/',
					},
				],
			},
			{
				'heading': 'Independent',
				'projects': [
					{
						'show': false,
						'name': 'The Canadian Jewish News',
						'url': 'https://thecjn.ca/',
					},
					{
						'show': true,
						'name': 'Scott Rankin',
						'url': 'https://scottrank.in/',
					},
					{
						'show': false,
						'name': 'Vegan Kitchen Club',
						'url': 'http://www.vegankitchen.club/',
					},
					{
						'show': true,
						'name': 'Luke Lalonde',
						'url': 'http://lukelalonde.com/',
					},
					{
						'show': false,
						'name': 'Nathan\'s Pizza',
						'url': 'https://nathans.pizza/',
					},
					{
						'show': false,
						'name': 'Arnold\'s Pizza',
						'url': 'https://arnolds.pizza/',
					},
					{
						'show': false,
						'name': 'Victoria Illustration Portfolio',
						'url': 'https://arnolds.pizza/',
					},
				],
			},
		],
	},
	'skills': {
		'heading': 'Skills',
		'description': '',
		'items': [
			{
				'heading': 'Languages',
				'items': [ 'HTML', 'CSS', 'JavaScript', 'PHP', 'SCSS', 'Twig' ],
			},
			{
				'heading': 'Frameworks and systems',
				'items': [ 'Angular', 'WordPress', 'Timber', 'Gutenberg', 'Gatsby', 'React' ],
			},
			{
				'heading': 'Version control',
				'items': [ 'Git', 'Subversion' ],
			},
			// {
			// 	'heading': 'Currently learning',
			// 	'items': [ 'Angular' ],
			// },
			// {
			// 	'heading': 'Dabbled in',
			// 	'items': [ 'Ruby on Rails', 'Angular' ],
			// },
		],
	},
	'resume': {
		'sections': [
			{
				'show': true,
				'type': 'skills',
				'heading': 'TODO Skills summary',
				'points': [
					'Multiple years of hands-on experience across a number of programming languages, tools and related management frameworks',
					'Quick learner, punctual, reliable, good moral conduct, motivated to learn and grow',
				],
				'items': [
					{
						'heading': 'Programming Languages',
						'items': [
							{ 'text': 'HTML' },
							{ 'text': 'CSS' },
							{ 'text': 'JS' },
							{ 'text': 'Twig' },
						],
					},
					{
						'heading': 'Frameworks',
						'items': [ { 'text': 'WP' }, { 'text': 'Gatsby' } ],
					},
					{
						'heading': 'Currently Learning',
						'items': [ { 'text': 'React' }, { 'text': 'Vue' } ],
					},
					{
						'heading': 'Additional Programs',
						'items': [
							{
								'text': 'Adobe Creative Suite',
								'supporting': '(Illustrator, InDesign, Photoshop)',
							},
							{ 'text': 'Sketch' },
						],
					},
					{
						'heading': 'Version control',
						'items': [ { 'text': 'Git' }, { 'text': 'Subversion' } ],
					},
				],
			},
			{
				'show': true,
				'type': 'standard',
				'heading': 'Employment History',
				'items': [
					{
						'show': true,
						'role': 'Front-end Developer',
						'org': 'Strategic Content Labs',
						'dates': { 'start': 'November 2016', 'end': 'Present' },
						'points': [
							'Led initiative to modernize build tools (grunt to gulp)',
							'halved AWS hosting bill at its peak by updating sites and their templates to serve appropriately-sized images using srcset and sizes',
							'smush pro plugin',
						],
					},
					{
						'show': true,
						'role': 'Front-end Developer',
						'org': 'Nurun',
						'dates': { 'start': 'August', 'end': 'October 2016' },
						'additional': [ { 'text': 'Short-term contract' } ],
						'points': [
							'Helped to manage bugs in JIRA',
							'Content population/updates in a variety of CMSs',
							'Prepared image assets',
							'Performed fixes on a number of WordPress sites',
						],
					},
					{
						'show': true,
						'role': 'Front-end Developer',
						'org': 'Community Agency',
						'dates': { 'start': 'August 2014', 'end': 'June 2016' },
						'points': [
							'Participated in the development of promotional sites for major brands, using designs from the creative department',
							'Brought static Photoshop designs to life, while remaining faithful to the source creative',
							'Spearheaded an initiative to modernize and redesign the agency’s email templates to ensure cross-client compatibility',
							'Worked closely with project and account managers to successfully meet deadlines',
						],
					},
					{
						'show': true,
						'role': 'Graphic Designer',
						'org': 'Brainrider',
						'dates': { 'start': 'June', 'end': 'August 2014' },
						'additional': [ { 'text': 'Short-term contract' } ],
						'points': [
							'Created a brand standards guide',
							'Revised banner ads',
							'Produced web page mockups',
							'Resolved site bugs',
						],
					},
					{
						'show': false,
						'role': 'Independent Developer/Designer',
						'dates': { 'start': 'March 2014', 'end': 'Present' },
						'points': [
							'Assisted individuals in establishing brands for their businesses',
							'Performed design and layout work for clients in the marketing field',
							'Designed several corporate publications',
							'Completed updates and bug fixes on existing sites',
						],
					},
					{
						'show': true,
						'role': 'Graphic & Web Designer',
						'org': 'Adecco Canada',
						'dates': { 'start': 'May 2012', 'end': 'January 2014' },
						'points': [
							'Created marketing materials (brochures, mailouts, magazines, trade show booth graphics, banners, email templates) for both national and specific field initiatives',
							'Maintained the company website through a CMS',
							'Reinforced the company’s image and branding guidelines by ensuring that the brand is portrayed consistently in every marketing campaign',
						],
					},
					{
						'show': false,
						'role': 'Production Technician',
						'org': 'James Signs',
						'dates': { 'start': 'July 2008', 'end': 'May 2012' },
						'points': [
							'Oversaw and took part in the careful production of graphics and materials',
							'Performed meticulous on-site installations/applications of signs and vinyl graphics',
							'Checked files for integrity, proper fonts, and colours',
						],
					},
				],
			},
			{
				'show': true,
				'type': 'standard',
				'heading': 'Academic achievement',
				'items': [
					{
						'show': true,
						'role': 'Front-end Development Immersive',
						'org': 'Juno College of Technology',
						'dates': { 'start': 'January', 'end': 'March 2014' },
						'points': [
							'Trained in the proper and responsible use of HTML and CSS',
							'Put Responsive Web Design concepts to use in order to accommodate various devices and screen sizes',
							'Learned to use JavaScript and jQuery to enhance browsing experience',
						],
					},
					{
						'show': true,
						'role': 'School of Communication Arts',
						'org': 'Seneca College of Applied Arts and Technology',
						'dates': { 'start': '2008', 'end': '2011' },
						'points': [
							'Advanced Diploma in Graphic Design with Honours',
							'Received President\'s Honour List recognition',
						],
					},
				],
			},
			{
				'show': true,
				'type': 'standard',
				'heading': 'Relevant experience',
				'items': [
					{
						'show': true,
						'role': 'Intro to Ruby on Rails',
						'org': 'Juno College of Technology',
						'dates': { 'start': 'February', 'end': 'April 2015' },
						'points': [
							'Learned about the MVC nature of Rails',
							'Introduced to concepts such as test-driven design, associations, routing, migrations',
							'Leveraged existing front-end skills to create web applications',
						],
					},
					{
						'show': true,
						'role': 'Mentor',
						'org': 'Canada Learning Code',
						'dates': { 'start': 'April 2014', 'end': 'present' },
						'points': [
							'Assisted workshop attendees in grasping HTML and CSS, and WordPress concepts',
							'Guided individuals to solutions by talking through their issues',
							'Offered advice on HTML and CSS best practices',
						],
					},
					{
						'show': false,
						'role': 'Design Intern',
						'org': 'Scott Thornley + Company',
						'dates': { 'start': 'March', 'end': 'April 2011' },
						'points': [
							'Produced and managed assets for the company’s new website',
							'Assisted in the assembly of pitch books',
							'Performed Photoshop work on images requiring repair, cleanup, and/or manipulation',
						],
					},
				],
			},
		],
	},
};

export default data;
