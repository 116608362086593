// ---------------
// REACT
// ---------------
import React from 'react';
import PropTypes from 'prop-types';

// ---------------
// DATA
// ---------------
import data from '../../../content/data';

// ---------------
// COMPONENTS
// ---------------
import Nathan from '../name/nathan';
import Foon from '../name/foon';

// ---------------
// HELPERS
// ---------------
import { smartApos, buddySystem } from '../../helpers/helpers';

// ---------------
// STYLES
// ---------------
import styles from './header.module.scss';


const { longDescription } = data;

const Header = ( { siteTitle } ) => (
	<header className={styles.default}>
		<h1 className={styles.name}>
			<span className={styles.hide}>{siteTitle}</span>
			<Nathan className={styles.nathan} />
			<Foon className={styles.foon} />
		</h1>
		<p className={styles.desc} dangerouslySetInnerHTML={ { __html: buddySystem( smartApos( longDescription ) ) } }></p>
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: '',
};

export default Header;
