const helpers = {
	/**
	 * Replace dumb apostrophe with smart apostrophe
	 *
	 * @param {string} string
	 *
	 * @return {string} `string` with smart apostrophes
	 */
	smartApos( string = '' ) {
		if ( !string || string.trim().length === 0 ) {
			return '';
		}

		return string.replace( '\'', '’' );
	},

	/**
	 * Add protocol
	 * https://stackoverflow.com/a/24657561
	 *
	 * @param {string} url Protocol-less URL
	 * @param {boolean} secure You want https?
	 *
	 * @return {string} `string` URL with protocol appended
	 *
	 */
	addProtocol( url = '', secure = true ) {
		if ( !url || url.trim().length === 0 ) {
			return '';
		}

		if ( !/^(?:f|ht)tps?:\/\//.test( url ) ) {
			url = secure ? `https://${url}` : `http://${url}`;
		}

		return url;
	},

	/**
	 * Replaces last occurrence of (space) in a string with nbsp, only if there is more than one instance of (space)
	 * https://stackoverflow.com/a/33734195
	 * https://stackoverflow.com/a/5238020
	 * https://stackoverflow.com/a/4009768
	 *
	 * @param {string} string String
	 *
	 * @return {string} String
	 */
	buddySystem( string = '' ) {
		if ( !string || string.trim().length === 0 ) {
			return '';
		}

		const count = ( string.match( / /g ) || [] ).length;
		const lastIndex = string.lastIndexOf( ' ' );

		if ( lastIndex === -1 || count <= 1 ) {
			return string;
		}

		return `${string.substr( 0, lastIndex )}${String.fromCharCode( 160 )}${string.substr( lastIndex + 1 )}`;
	},
};

module.exports = helpers;
