// ---------------
// REACT
// ---------------
import React from 'react';


const Foon = ( { className } ) => (
	<svg className={className} viewBox="0 0 166 50" alt="">
		<path d="M0 48.824V1.176h30.589v10H10V20h18.236v10H10v18.824H0zM155.995 1.176h10V50L138.76 23.411v25.413h-10V0l27.235 26.706V1.176zM100.48.59a24.412 24.412 0 1 1 0 48.823c-8.642 0-15.501-4.384-20.66-11.132a38.685 38.685 0 0 1-1.681-2.403 38.672 38.672 0 0 1-1.681 2.402c-5.16 6.749-12.019 11.133-20.66 11.133a24.412 24.412 0 1 1 0-48.823c8.641 0 15.5 4.384 20.66 11.132a38.685 38.685 0 0 1 1.68 2.403 38.655 38.655 0 0 1 1.682-2.402C84.979 4.974 91.838.59 100.48.59zM56.05 39.412C67.584 39.412 72.835 25 72.835 25s-5.251-14.412-16.783-14.412c-7.96 0-14.412 6.452-14.412 14.412 0 7.96 6.452 14.412 14.412 14.412zm44.684 0c7.96 0 14.412-6.452 14.412-14.412 0-7.96-6.452-14.412-14.412-14.412C89.202 10.588 83.95 25 83.95 25s5.252 14.412 16.784 14.412z"/>
	</svg>
);

export default Foon;
