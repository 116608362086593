// ---------------
// REACT
// ---------------
import React from 'react';


const Nathan = ( { className } ) => (
	<svg className={className} viewBox="0 0 239 50" alt="">
		<path d="M27.235 26.706V1.176h10V50L10 23.411v25.413H0V0l27.235 26.706zm13.208 22.118L62.854 0l22.412 48.824H74.15l-2.65-6.471H54.207l-2.647 6.47H40.443zM57.84 33.529h10.118l-5.06-12.47-5.058 12.47zM80.012 11.18v-10h33.236v10H101.66v37.648h-10V11.18H80.012zm60.787 8.824V1.18h10v47.648h-10V30.004h-14.177v18.824h-10V1.18h10v18.824H140.8zm13.034 28.82L176.244 0l22.412 48.824h-11.118l-2.647-6.471h-17.293l-2.648 6.47h-11.117zm17.398-15.295h10.118l-5.059-12.47-5.059 12.47zM229 1.176h10V50l-27.235-26.589v25.413h-10V0L229 26.706V1.176z"/>
	</svg>
);

export default Nathan;
