// ---------------
// REACT
// ---------------
import React from 'react';

// ---------------
// DATA
// ---------------
import data from '../../../content/data';

// ---------------
// COMPONENTS
// ---------------
import Obfuscate from 'react-obfuscate';

// ---------------
// STYLES
// ---------------
import styles from './footer.module.scss';


const { contact, social } = data;

const Footer = () => (
	<footer className={styles.footer}>
		<div className={styles.inner}>
			<div className={styles.heading}>
				<h1>Connect</h1>
			</div>
			<div className={styles.content}>
				<div className={styles.contentItem}>
					<ul>
						<li>
							<Obfuscate email={contact.email.public}>Email</Obfuscate>
						</li>

						{social.filter( item => item.show ).map( ( item, index ) => (
							<li key={index}>
								<a href={item.url} rel="noopener noreferrer" target="_blank" >{item.name}</a>
							</li>
						) )}
					</ul>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
